import { Status } from '~/constants/general/Status'
export const useSelectedCompanyForWholeAppStatus = () => {
  const selectedCompanyForWholeAppStatus = useState(() => Status.INITIAL)
  function changeSelectedCompanyForWholeAppStatus(toStatus: Status) {
    selectedCompanyForWholeAppStatus.value = toStatus
  }

  return {
    selectedCompanyForWholeAppStatus,
    changeSelectedCompanyForWholeAppStatus,
  }
}
