import IGenericErrorApiTemplate, {
  IGeneralErrorApi,
} from '~/store/common/IGenericErrorApiTemplate'
import ISelectedCompanyForWholeAppState from '~/store/selectedCompanyForWholeApp/interfaces/ISelectedCompanyForWholeAppState'
import { ICompany } from '~/store/companies/types/companyTypes'

export const getters = {
  getSelectedCompany:
    (state: ISelectedCompanyForWholeAppState) => (): ICompany | null => {
      return state.selectedCompany
    },
  getFoundCompanies:
    (state: ISelectedCompanyForWholeAppState) => (): ICompany[] => {
      return state.foundCompanies
    },
  getErrorsBag:
    (state: ISelectedCompanyForWholeAppState) =>
    (): IGenericErrorApiTemplate | IGeneralErrorApi => {
      return state.errors
    },
}
