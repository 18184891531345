// packages
import { defineStore } from 'pinia'
// constants
import { SELECTED_COMPANY_FOR_WHOLE_APP } from '~/constants/stores/stores'
// store components
import { getters } from '~/store/selectedCompanyForWholeApp/getters'
import { actions } from '~/store/selectedCompanyForWholeApp/actions'
import IGenericErrorApiTemplate from '~/store/common/IGenericErrorApiTemplate'
import { ICompany } from '~/store/companies/types/companyTypes'
import ISelectedCompanyForWholeAppState from '~/store/selectedCompanyForWholeApp/interfaces/ISelectedCompanyForWholeAppState'

const state = (): ISelectedCompanyForWholeAppState => ({
  selectedCompany: <ICompany | null>null,
  foundCompanies: <ICompany[]>[],
  errors: <IGenericErrorApiTemplate>{},
})

export const useSelectedCompanyForWholeAppStore = defineStore(
  SELECTED_COMPANY_FOR_WHOLE_APP,
  {
    state,
    actions,
    getters,
    persist: {
      storage: persistedState.localStorage,
    },
  },
)
